import styles from "./index.module.scss"
import React, { useState, useEffect } from "react"
import { useForm } from "react-form"
import Button from "../../button/button"
import SelectBox from "../../form/select-box/select-box"
import TextField from "../../form/text-field/text-field"
import membershipService from "../../../../services/membership"
import RadioButtonList from "../../form/radio-button-list/radio-button-list"

const FamilyMemberShipForm = ({ membershipTypes }) => {
  const [submitAttempted, setSubmitAttempted] = useState()
  const [membershipType, setMembershipType] = useState()
  const [showText, setShowText] = useState(false);
  const [disable, setDisable] = useState(true);
  const [toggle, setToggle] = useState(false);

  const formInstance = useForm({
    debugForm: false,
    onSubmit: values => {
      const billing_address = {
        address1: values.address1,
        address2: values.address2 ? values.address2 : '',
        city: values.city,
        province: values.province ? values.province : '',
        state: values.state ? values.state : '',
        country: membershipType.value,
        postalCode: values.postalCode ? values.postalCode : '',
        zipCode: values.zipCode ? values.zipCode : ''
      }
      const membershipTypeUpdatedObj = {
        billing_address: billing_address,
        amount: values.amount,
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        telephone: values.phone
      }

      console.log("updatedObj", membershipTypeUpdatedObj);

      membershipService.sendPayment(membershipTypeUpdatedObj).then(
        result => {
          console.log('Payment sent')
        },
        error => {
          console.log('Error')
        }
      )
    },

    validate: (values, instance) => {
      setSubmitAttempted(false)
    },
  })



  const familyDataList = [{
    value: 40.00,
    label: "1 year family subscription - $40.00",
  },
  {
    value: 80.00,
    label: "2 year family subscription - $80.00",
  }
  ]

  const getFamilyDataList = () => {
    return familyDataList
  }
  const familyDataListOptions = getFamilyDataList();


  const selectionDataList = [{
    value: "CA",
    label: "Canada",
  },
  {
    value: "US",
    label: "United States",
  }
  ]

  const getSelectionDataList = () => {
    return selectionDataList
  }
  const selectionDataListOptions = getSelectionDataList();

  const handleChangeMembership = (value) => {
    if (value.value !== "CA") {
      setToggle(true)
      setMembershipType(value)
    } else {
      setToggle(false)
      setMembershipType(value)
    }
  }

  const handleOnChange = (value) => {
    setShowText(value)
    setDisable(value === '');
  }

  const { Form, meta } = formInstance

  return (
    <div>
      <Form>

        <div style={{ fontSize: "20px", fontWeight: "bold" }}>
          Contact Info
        </div>
        <br />
        <div>
          <div style={{ display: "flex" }}>
            <div >
              <TextField
                field="firstName"
                placeholder="First Name*"
                required
                validate={value => {
                  return !value ? "This is required!" : false
                }}
                mandatory
              />
            </div>
            <div style={{ marginLeft: "7px" }}>
              <TextField
                field="lastName"
                placeholder="Last Name*"
                required
                validate={value => {
                  return !value ? "This is required!" : false
                }}
                mandatory
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <TextField
                field="email"
                placeholder="Email*"
                required
                validate={value => {
                  return !value ? "This is required!" : false
                }}
                mandatory
              />
            </div>
            <div style={{ marginLeft: "7px" }}>
              <TextField
                field="phone"
                placeholder="Telephone"
                required
              />
            </div>
          </div>
        </div>

        <div style={{ fontSize: "20px", fontWeight: "bold" }}>
          Address
        </div>
        <br />
        <div>
          <TextField
            field="address1"
            placeholder="Street Address"
            required
            // onChange={handleChange}
            validate={value => {
              return !value ? "This is required!" : false
            }}
            mandatory
          />
        </div>

        <div>
          <div style={{ display: "flex" }}>
            <div >
              <TextField
                field="address2"
                placeholder="Apt, suite, etc"
                // onChange={handleChange}
                mandatory
              />
            </div>
            <div style={{ marginLeft: "7px" }}>
              <TextField
                field="city"
                placeholder="City"
                // onChange={handleChange}
                required
                validate={value => {
                  return !value ? "This is required!" : false
                }}
                mandatory
              />
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <SelectBox
              field="types"
              options={selectionDataListOptions}
              value={membershipType}
              onChange={handleChangeMembership}
              defaultValue={'Type'}
            />
            {!toggle && <div style={{ marginLeft: "7px" }}>
              <TextField
                field="province"
                placeholder="Province"
                required
              />
            </div>}
            {toggle && <div style={{ marginLeft: "7px" }}>
              <TextField
                field="state"
                placeholder="State"
                required
              />
            </div>}
          </div>
          {!toggle && <div>
            <TextField
              field="postalCode"
              placeholder="Postal Code"
              required
            />
          </div>}
          {toggle && <div>
            <TextField
              field="zipCode"
              placeholder="Zip Code"
              required
            />
          </div>}
        </div>

        <div style={{ fontSize: "15px", fontWeight: "bold" }}>Membership Options</div>
        <p style={{ fontSize: "12px" }}>select membership length</p>


        <div>
          <RadioButtonList
            field="amount"
            options={familyDataListOptions}
            fnSetValue={handleOnChange}
          />
        </div>


        <br />
        <br />
        <div style={{ fontSize: "20px", fontWeight: "bold", display: "flex" }}>Total Payment:<span style={{ color: "#397567", fontWeight: "bold", marginLeft: "10px", fontSize: "22px" }}>$</span>
          <div style={{ color: "#397567", fontWeight: "bold", fontSize: "22px" }}>{showText}</div>
        </div>

        <br />
        <br />
        <div>
          <span className="done">
            <Button
              id="checkout"
              label="Proceed to Checkout"
              type="submit"
              btnStyle="primary"
              btnSize="small"
              disableStatus={disable}
            />
          </span>
        </div>

      </Form>
    </div>
  )
}
export default FamilyMemberShipForm