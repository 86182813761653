import styles from "./index.module.scss"
import React, { useState, useEffect } from "react"
import { useForm } from "react-form"
import Button from "../button/button"
import TextField from "../form/text-field/text-field"
import TextArea from "../form/text-area/text-area"
import SelectBox from "../form/select-box/select-box"
import membershipService from "../../../services/membership"
import RadioButtonList from "../form/radio-button-list/radio-button-list"
import { Select } from "@mui/material"

const MemberShipForm = ({ membershipTypes }) => {
  const [submitAttempted, setSubmitAttempted] = useState()
  const [membershipType, setMembershipType] = useState()
  const [disable, setDisable] = useState(true);
  const [showText, setShowText] = useState(false);
  const [toggle, setToggle] = useState(false);


  const formInstance = useForm({
    debugForm: false,
    onSubmit: values => {
      const billing_address = {
        address1: values.address1,
        address2: values.address2 ? values.address2 : '',
        city: values.city,
        province: values.province ? values.province : '',
        state: values.state ? values.state : '',
        country: membershipType.value,
        postalCode: values.postalCode ? values.postalCode : '',
        zipCode: values.zipCode ? values.zipCode : ''
      }
      const membershipTypeUpdatedObj = {
        billing_address: billing_address,
        amount: values.amount,
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        telephone: values.phone
      }

      console.log("updatedObj", membershipTypeUpdatedObj);

      membershipService.sendPayment(membershipTypeUpdatedObj).then(
        result => {
          console.log('Payment sent')
        },
        error => {
          console.log('Error')
        }
      )
    },

    validate: (values, instance) => {
      setSubmitAttempted(false)
    },
  })




  const individualDataList = [{
    value: 25.00,
    label: "1 year individual subscription - $25.00",
  },
  {
    value: 50.00,
    label: "2 year individual subscription - $50.00",
  },
  {
    value: 125.00,
    label: "5 year individual subscription - $125.00",
  },
  {
    value: 250.00,
    label: "10 year individual subscription - $250.00",
  }
  ]

  const getIndividualDataList = () => {
    return individualDataList
  }
  const individualDataListOptions = getIndividualDataList();


  const selectionDataList = [{
    value: "CA",
    label: "Canada",
  },
  {
    value: "US",
    label: "United States",
  }
  ]

  const getSelectionDataList = () => {
    return selectionDataList
  }
  const selectionDataListOptions = getSelectionDataList();

  const handleChangeMembership = (value) => {
    if (value.value !== "CA") {
      setToggle(true)
      setMembershipType(value)
    } else {
      setToggle(false)
      setMembershipType(value)
    }
  }

  const handleOnChange = (value) => {
    setShowText(value)
    setDisable(value === '');
  }

  const handleChange = (event) => {
    setDisable(event.target.value === '');
  }

  const showIcon = () => {

  }

  const { Form, meta } = formInstance

  return (
    <div>
      <Form>

        <div style={{ fontSize: "20px", fontWeight: "bold" }}>
          Contact Info
        </div>
        <br />
        <div>
          <div style={{ display: "flex" }}>
            <div >
              <TextField
                field="firstName"
                placeholder="First Name*"
                required
                // onChange={handleChange}
                validate={value => {
                  return !value ? "This is required!" : false
                }}
                mandatory
              />
            </div>
            <div style={{ marginLeft: "7px" }}>
              <TextField
                field="lastName"
                placeholder="Last Name*"
                // onChange={handleChange}
                required
                validate={value => {
                  return !value ? "This is required!" : false
                }}
                mandatory
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <TextField
                field="email"
                placeholder="Email*"
                // onChange={handleChange}
                required
                validate={value => {
                  return !value ? "This is required!" : false
                }}
                mandatory
              />
            </div>
            <div style={{ marginLeft: "7px" }}>
              <TextField
                field="phone"
                placeholder="Telephone"
                required
              />
            </div>
          </div>
        </div>

        <div style={{ fontSize: "20px", fontWeight: "bold" }}>
          Address
        </div>
        <br />
        <div>
          <TextField
            field="address1"
            placeholder="Street Address"
            required
            // onChange={handleChange}
            validate={value => {
              return !value ? "This is required!" : false
            }}
            mandatory
          />
        </div>

        <div>
          <div style={{ display: "flex" }}>
            <div >
              <TextField
                field="address2"
                placeholder="Apt, suite, etc"
                // onChange={handleChange}
                mandatory
              />
            </div>
            <div style={{ marginLeft: "7px" }}>
              <TextField
                field="city"
                placeholder="City"
                // onChange={handleChange}
                required
                validate={value => {
                  return !value ? "This is required!" : false
                }}
                mandatory
              />
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <SelectBox
              field="types"
              options={selectionDataListOptions}
              value={membershipType}
              onChange={handleChangeMembership}
              defaultValue={'Type'}
            />
            {!toggle && <div style={{ marginLeft: "7px" }}>
              <TextField
                field="province"
                placeholder="Province"
                required
              />
            </div>}
            {toggle && <div style={{ marginLeft: "7px" }}>
              <TextField
                field="state"
                placeholder="State"
                required
              />
            </div>}
          </div>
          {!toggle && <div>
            <TextField
              field="postalCode"
              placeholder="Postal Code"
              required
            />
          </div>}
          {toggle && <div>
            <TextField
              field="zipCode"
              placeholder="Zip Code"
              required
            />
          </div>}
        </div>

        <div style={{ fontSize: "15px", fontWeight: "bold" }}>Membership Options</div>
        <p style={{ fontSize: "12px" }}>select membership length</p>

        <div>
          <RadioButtonList
            field="amount"
            options={individualDataListOptions}
            fnSetValue={handleOnChange}
          // onClick={showIcon}
          />
        </div>

        <br />
        <br />
        <div style={{ fontSize: "20px", fontWeight: "bold", display: "flex" }}>Total Payment:<span style={{ color: "#397567", fontWeight: "bold", marginLeft: "10px", fontSize: "22px" }}>$</span>
          <div style={{ color: "#397567", fontWeight: "bold", fontSize: "22px" }}>{showText}</div>
        </div>

        <br />
        <br />
        <div>
          <span className="done">
            <Button
              id="checkout"
              label="Proceed to Checkout"
              type="submit"
              btnStyle="primary"
              btnSize="small"
              disableStatus={disable}
            />
          </span>
        </div>
      </Form>
    </div>
  )
}
export default MemberShipForm


















{/* 
        <div className={styles.select}>


          <SelectBox
            field="types"
            options={membershipTypes}
            value={membershipType}
            onChange={handleChangeMembership}
            defaultValue={'Type'}
          />


        </div> */}


{/* {membershipType &&
          <div>
            <div className={styles.info}>
              <div className={styles.field}>
                {/* <div>Amount:</div>
                <div>Tax:</div> 
                <div>Total Amount:</div>
              </div>
              <div className={styles.values}> 
                {/* <div> CAD ${(membershipType.price).toFixed(2)}</div> */}
{/* <div> CAD ${(membershipType.tax).toFixed(2)}</div> */ }
{/* <div> CAD ${(membershipType.price).toFixed(2)}</div>
              </div>
            </div>

            <br />
            <div className="btns">
              <span className="done">
                <Button
                  id="create-group"
                  label="Proceed to Checkout"
                  type="submit"
                  btnStyle="primary"
                  btnSize="small"
                />
              </span>
            </div>
          </div>
        } */}